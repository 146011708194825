'use client';

import { createPortal } from 'react-dom';
import { UserInterfaceElements } from '@cesdk/cesdk-js';
import { MimeType, Source } from '@cesdk/engine';
import { useEffect } from 'react';
import { useToastNotificationContext } from '@sendible/design-system/src';
import { NotificationTypeEnum } from '@sendible/design-system/src/components/molecules/Toast';
import { CeSDKWrapper } from './index.styles';

import { initPhotoEditorUIConfig } from './PhotoEditor/PhotoEditorUIConfig';
import CreativeEditor, { useConfig, useConfigure } from './PhotoEditor/lib/CreativeEditor';
import { EDITOR_EXPORT, EDITOR_CLOSE } from '.';

type EditorProps = {
  originalImage: Source;
  halfSizeImage: Source;
  quarterSizeImage: Source;
  serviceSet: ServiceSetType;
  restrictedServices: {
    pinterest: boolean;
    threads: boolean;
  };
  resetState: () => void;
};

const Editor = ({ originalImage, halfSizeImage, quarterSizeImage, serviceSet, restrictedServices, resetState }: EditorProps) => {
  const { showToastNotification } = useToastNotificationContext();
  // updated
  const exportSourceSet = [halfSizeImage, quarterSizeImage, originalImage];
  const editingSourceSet = [
    halfSizeImage,
    quarterSizeImage,
    {
      height: originalImage.height,
      width: originalImage.width,
      // We want to use a smaller resolution image instead of the full resolution during editing.
      // By passing the original height/width, we ensure that we still got the proper dimensions if crop presets are used.
      uri: halfSizeImage.uri,
    },
  ];

  useEffect(() => {
    document.body.style.margin = '0';

    window.pendo.track('[CE.SDK] Editor Opens', { fileType: 'image' });
  }, []);

  const config = useConfig(
    () => ({
      role: 'Adopter',
      theme: 'dark',
      license: 'VHjEPgPLBuDp-hnJmlQe-UAEu1GdyVUXuNAyIuvheFkzoxpkPdl4A1oKbCGgmjsl',
      ui: {
        elements: {
          blocks: {
            '//ly.img.ubq/page': {
              format: false,
              stroke: { show: false },
              manage: false,
            },
          },
          panels: {
            inspector: {
              show: true,
              position: UserInterfaceElements.PanelPosition.Left,
              floating: false,
            },
            settings: false,
          },
          libraries: {
            replace: {
              floating: false,
              autoClose: true,
            },
            insert: {
              autoClose: true,
              floating: false,
            },
          },
          navigation: {
            title: 'Photo Editor',
            action: {
              close: true,
            },
          },
        },
      },
      i18n: {
        en: {
          'component.fileOperation.exportImage': 'Export Image',
        },
      },
      callbacks: {
        onClose: () => {
          window.pendo.track('[CE.SDK] Editor Close', { fileType: 'image' });
          window.parent.postMessage({ event: EDITOR_CLOSE }, '*');
          resetState();
        },
      },
    }),
    [originalImage, halfSizeImage, quarterSizeImage]
  );

  let configure = null;

  configure = useConfigure(async (instance) => {
    await instance.addDefaultAssetSources();
    await instance.addDemoAssetSources({ sceneMode: 'Design' });

    instance.ui.registerComponent('export.button', ({ builder: { Button }, engine }) => {
      Button('export', {
        label: 'Save',
        color: 'accent',
        onClick: async () => {
          const dialog = instance.ui.showDialog({
            type: 'loading',
            content: 'Saving updated image. This may take some time. Please do not close this window.',
            progress: 'indeterminate',
          });

          const page = engine.scene.getCurrentPage()!;
          const fill = engine.block.getFill(page);
          const sourceSet = engine.block.getSourceSet(fill, 'fill/image/sourceSet');

          engine.block.setSourceSet(fill, 'fill/image/sourceSet', exportSourceSet);
          try {
            // export the canvas:
            const blob = await engine.block.export(page, 'image/jpeg' as MimeType, {
              jpegQuality: 0.9,
            });

            if (blob) {
              window.pendo.track('[CE.SDK] Editor Export', { fileType: 'image' });
              window.parent.postMessage({ event: EDITOR_EXPORT, blob }, '*');
              resetState();
            } else {
              throw new Error('Blob creation failed');
            }
            engine.block.setSourceSet(fill, 'fill/image/sourceSet', sourceSet);

            instance.ui.closeDialog(dialog);
          } catch (error) {
            instance.ui.closeDialog(dialog);
            showToastNotification({
              notificationText: 'There was an issue saving your image. Please try again',
              type: NotificationTypeEnum.Error,
              showCloseButton: true,
            });
          }
        },
      });
    });

    // Sets the order for transform mode by default
    const defaulNavOrder = instance.ui.getNavigationBarOrder();

    instance.ui.setNavigationBarOrder([...defaulNavOrder, 'export.button']);

    const cleanup = await initPhotoEditorUIConfig({
      instance,
      originalImage,
      options: {
        sourceSet: editingSourceSet,
      },
      restrictedServices,
      serviceSet,
    });

    const page = instance.engine.scene.getCurrentPage();

    instance.engine.block.select(page!);
    await new Promise((resolve) => {
      setTimeout(resolve, 100);
    });
    instance.ui.openPanel('ly.img.page-crop');
    instance.engine.editor.setEditMode('Crop');

    return cleanup;
  }, []);

  if (!originalImage) {
    configure = undefined;
  }

  return (
    <div className="cesdkWrapperStyle">
      {createPortal(
        <CeSDKWrapper>
          <CreativeEditor
            className="cesdk-photo-editor cesdkStyle"
            style={{
              // Hide the inspector bar
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              '--ubq-InspectorBar-background': 'var(--ubq-canvas)',
            }}
            config={config}
            configure={configure}
          />
        </CeSDKWrapper>,
        document.getElementById('modal-root') as HTMLElement
      )}
    </div>
  );
};

export default Editor;
