/* eslint-disable no-nested-ternary */
import { Heading, Icon, Spinner, Text, Tooltip } from '@sendible/design-system';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useGetTikTokOverview } from '@sendible/frontendv2/src/models/reports';
import { Avatar, TrendArrow, Container, InfoBox, Info, MetricsBox, Row, MetricsList, MetricItem } from './index.styles';
import { emptyApiData } from './mocks';
import { useTikTokReportContext } from '../../context';

const Overview = () => {
  const { t } = useTranslation('reports');
  const [hasNoImage, setHasNoImage] = useState(false);
  const { data, isLoading, isError } = useGetTikTokOverview();
  const { currentAccountObject } = useTikTokReportContext();
  const needsReconnecting = currentAccountObject?.reconnection_info.needs_reconnecting;

  if (!currentAccountObject) return null;

  const { metrics } = data || emptyApiData;

  return (
    <Container>
      <InfoBox>
        <Icon
          customClass="tiktok-logo"
          name="tiktok_color"
          useStroke={false}
        />
        <div>
          <Heading
            semantic="h2"
            variation="layout-title_28"
          >
            {t('tiktok_name')}
          </Heading>
          <Text variation="body_16">{t('activity_overview')}</Text>
        </div>
        {isError ? (
          <Info>
            <Icon
              name="danger"
              color="semanticDanger"
            />
            <Text variation="body_12">{t('generic_report_error')}</Text>
          </Info>
        ) : needsReconnecting ? (
          <Info>
            <Icon
              name="danger"
              color="semanticDanger"
            />
            <Text variation="body_12">{t('profile_needs_reconnection')}</Text>
          </Info>
        ) : (
          <Info>
            <Icon
              name="info"
              color="semanticInfo"
            />
            <Text variation="body_12">{t('report_delays')}</Text>
          </Info>
        )}
      </InfoBox>
      <MetricsBox>
        {isLoading ? (
          <Spinner size="lg" />
        ) : (
          <>
            <MetricsList>
              {metrics.map(({ current, label, difference, percentage, isPositive, tooltipText }) => {
                const shouldDisplaySigns = difference !== '-' && percentage !== '-' && difference !== '0' && percentage !== '0';

                return (
                  <MetricItem
                    key={`overview-metric-${label}`}
                    className="metric-item"
                  >
                    <Heading
                      semantic="h6"
                      variation="layout-title_42"
                    >
                      {current}
                    </Heading>
                    <Row>
                      <p>{label}</p>
                      {tooltipText && (
                        <Tooltip
                          content={tooltipText}
                          noDecoration
                          defaultPosition="top"
                          maxWidth
                        >
                          <Icon
                            name="help"
                            color="brandDark"
                            strokeWidth={2}
                          />
                        </Tooltip>
                      )}
                    </Row>
                    <Row>
                      {shouldDisplaySigns && (
                        <TrendArrow $isPositive={isPositive}>
                          <Icon
                            name={isPositive ? 'arrow_up_right' : 'arrow_down_left'}
                            color="brandLight"
                          />
                        </TrendArrow>
                      )}
                      <Text
                        color={
                          difference === '-' && percentage === '-'
                            ? 'brandDarkTint20'
                            : isPositive
                            ? 'semanticSuccessShade50'
                            : 'semanticDangerShade20'
                        }
                        variation="body_12"
                      >
                        {shouldDisplaySigns && isPositive && '+'}
                        {difference} {`(${shouldDisplaySigns && isPositive ? '+' : ''}${percentage}%)`}
                      </Text>
                    </Row>
                  </MetricItem>
                );
              })}
            </MetricsList>
            <Avatar>
              {currentAccountObject?.avatar && !hasNoImage ? (
                <img
                  src={currentAccountObject?.avatar}
                  alt=""
                  onError={() => setHasNoImage(true)}
                />
              ) : (
                <p>{currentAccountObject.username[0]}</p>
              )}
            </Avatar>
          </>
        )}
      </MetricsBox>
    </Container>
  );
};

export { Overview };
