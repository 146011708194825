/* eslint-disable no-nested-ternary */
import { useRef, useMemo } from 'react';
import { Spinner, Text } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { useGetTikTokPublishing } from '@sendible/frontendv2/src/models/reports';
import { Chart } from './components/Chart';
import { chartDummyData } from '../mocks';
import {
  Container,
  StatsContainer,
  TopMetrics,
  StatsTable,
  StatRow,
  ChartHeader,
  Wrapper,
  ChartLegendContainer,
  LoadingWrapper,
} from '../index.styles';
import { Warning } from '../..';

export const FollowersOnline = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('reports');
  const { data, isLoading, isError } = useGetTikTokPublishing();

  const translations = useMemo(
    () => ({
      followersOnline: t('followers_online'),
      summary: t('summary'),
      highestFollowersOnline: t('highest_followers_online'),
      highestEngagementWeek: t('highest_engagement_week'),
      highestEngagementFootnote: t('highest_engagement_footnote'),
      needsMoreData: t('followers_online_no_data'),
    }),
    [t]
  );

  const noData = !!(!data?.followersOnline || !data?.followersOnline?.followersActivity.length);

  const {
    followersOnline: { followersActivity, summary },
  } = noData ? chartDummyData : data;

  const mostFrequentDay = summary?.mostEngagementDate ? new Date(summary.mostEngagementDate).toLocaleDateString(undefined, { weekday: 'long' }) : '-';

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Spinner size="lg" />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      {isError ? (
        <Warning
          text={t('generic_report_error')}
          type="danger"
          color="semanticDangerShade20"
        />
      ) : noData ? (
        <Warning
          text={translations.needsMoreData}
          type="info"
          color="semanticInfo"
        />
      ) : null}
      <Container
        ref={containerRef}
        $isHidden={noData}
      >
        <ChartHeader>{translations.followersOnline}</ChartHeader>
        <ChartLegendContainer>
          <Chart followersActivity={followersActivity} />
        </ChartLegendContainer>
        {!noData && (
          <StatsContainer
            orientation="column"
            contentJustify="space-between"
          >
            <TopMetrics orientation="column">
              <h3>{translations.summary}</h3>
              <StatsTable margin={['s0', 's0', 's16', 's0']}>
                <StatRow contentJustify="space-between">
                  <div>{translations.highestFollowersOnline}</div>
                  <div>{summary?.mostFollowersCount || '-'}</div>
                </StatRow>
                <StatRow contentJustify="space-between">
                  <div>{translations.highestEngagementWeek} *</div>
                  <div>{mostFrequentDay}</div>
                </StatRow>
              </StatsTable>
              <Text variation="body_12">* {translations.highestEngagementFootnote}</Text>
            </TopMetrics>
          </StatsContainer>
        )}
      </Container>
    </Wrapper>
  );
};
