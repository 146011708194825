import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetTikTokProfiles } from '@sendible/frontendv2/src/models/reports';
import { pages } from '@sendible/frontendv2/src/routes';

type TikTokReportContextType = {
  date: TikTokReportDate;
  setDate: Dispatch<SetStateAction<TikTokReportDate>>;
  endDate: string | undefined;
  startDate: string | undefined;
  currentAccountObject?: TikTokAccountType;
};

export const TikTokReportContext = createContext({} as TikTokReportContextType);

export const TikTokReportProvider = ({ children }: Component) => {
  const [date, setDate] = useState<TikTokReportDate>({ text: 'Last 7 days', altText: 'vs Previous 7 days', action: 'last7Days' });
  const navigate = useNavigate();
  const { id: paramIdString } = useParams();
  const accountId = paramIdString ? parseInt(paramIdString, 10) : null;
  const { data } = useGetTikTokProfiles();
  const currentAccountObject = data?.accounts.find((account) => account.id === accountId);

  useEffect(() => {
    if (data?.accounts && data.accounts.length) {
      let accountObject = data.accounts.find((account) => accountId && account.id === accountId);

      if (!accountObject) {
        [accountObject] = data.accounts;
        navigate(`${pages.Measure.tiktok}/${accountObject.id}`);
      }
    } else {
      navigate(`${pages.Measure.tiktok}`);
    }
  }, [data?.accounts, paramIdString]);

  const today = new Date().toISOString().substring(0, 10);
  const oneWeekAgo = new Date();
  const oneMonthAgo = new Date();
  const twoMonthsAgo = new Date();

  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);
  twoMonthsAgo.setDate(twoMonthsAgo.getDate() - 60);

  const dates: Record<TikTokReportDateAction, string> = {
    last7Days: oneWeekAgo.toISOString().substring(0, 10),
    last30Days: oneMonthAgo.toISOString().substring(0, 10),
    last60Days: twoMonthsAgo.toISOString().substring(0, 10),
  };

  const startDate = dates[date.action];
  const endDate = today;

  return (
    <TikTokReportContext.Provider
      value={{
        date,
        endDate,
        startDate,
        setDate,
        currentAccountObject,
      }}
    >
      {children}
    </TikTokReportContext.Provider>
  );
};

export const useTikTokReportContext = (): TikTokReportContextType => useContext(TikTokReportContext);
