import { Icon } from '@sendible/design-system/src';
import { capitalizeFirst, turnCamelCaseIntoReadableText } from '@sendible/common';
import { useState } from 'react';
import { Caption, Container, DateInfo, IconBox, ProfileThumbBox, Thumb, Data, DataItem, Profile, ProfileInfo, Top } from './index.styles';
import { VideoFilterType } from '../..';

interface TikTokVideoCardProps {
  activeMetric: VideoFilterType;
  caption: string;
  displayName: string;
  engagement: TikTokVideoType['engagement'];
  impressions: TikTokVideoType['impressions'];
  postedDate: string;
  profileAvatar: string;
  shareUrl: string;
  thumbnailUrl: string;
  userName: string;
  videoId: string;
  watchMetrics: TikTokVideoType['watchMetrics'];
}

export const TikTokVideoCard = ({
  activeMetric,
  caption,
  displayName,
  engagement,
  impressions,
  postedDate,
  profileAvatar,
  shareUrl,
  thumbnailUrl,
  userName,
  videoId,
  watchMetrics,
}: TikTokVideoCardProps) => {
  const [showImage, setShowImage] = useState(true);

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  const date = new Date(postedDate);
  const localePostedDate = date.toLocaleTimeString(navigator.language, options);

  const dataObject = {
    engagement: Object.entries(engagement),
    sources: Object.entries(impressions),
    watch_metrics: Object.entries(watchMetrics),
  };

  const selectedData = dataObject[activeMetric];

  const dataList = selectedData.map((data, index) => (
    <DataItem
      key={data[0]}
      $hideBorder={index === selectedData.length - 1}
    >
      <p>
        <b>{capitalizeFirst(turnCamelCaseIntoReadableText(data[0]).toLowerCase())}</b>
      </p>
      <p>{data[1]}</p>
    </DataItem>
  ));

  return (
    <Container>
      <Top>
        <DateInfo>{localePostedDate}</DateInfo>
        <Profile>
          <ProfileThumbBox>
            <IconBox $color="socialTiktok">
              <Icon
                color="brandLight"
                name="tiktok"
                useStroke={false}
              />
            </IconBox>
            <img
              alt=""
              src={profileAvatar}
            />
          </ProfileThumbBox>
          <ProfileInfo>
            <p>
              <b>{displayName}</b>
            </p>
            <p>{`@${userName}`}</p>
          </ProfileInfo>
        </Profile>
        <Caption>{caption}</Caption>
      </Top>
      <Thumb
        href={shareUrl}
        target="_blank"
        rel="noreferrer no"
      >
        <Icon name="play" />
        {showImage && thumbnailUrl && (
          <img
            src={thumbnailUrl}
            alt={videoId}
            onError={() => setShowImage(false)}
          />
        )}
      </Thumb>
      <Data>{dataList}</Data>
    </Container>
  );
};
