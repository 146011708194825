import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MediaSystemComponents } from './components/MediaSystemComponents';
import { useBackgroundUploaderContext } from './components/BackgroundUploader/context';
import { ComposeBoxProvider } from './composeBoxContext';

export const FrontendV2Components = () => {
  const [sharedState] = useBridgeContext();
  const {
    closeUploader,
    cancelUpload,
    composeBoxSkeletons,
    uploadingIdsFromMediaLibrary,
    setComposeBoxUploadedFileIds,
    setUploadingIdsFromComposeBox,
  } = useBackgroundUploaderContext();
  const navigate = useNavigate();

  useEffect(() => {
    composeBoxSkeletons.forEach(({ id, status }) => status !== 'UploadFailed' && cancelUpload(id));

    setComposeBoxUploadedFileIds([]);
    setUploadingIdsFromComposeBox([]);

    if (!uploadingIdsFromMediaLibrary.length) {
      closeUploader();
    }
  }, [sharedState?.compose?.isComposeBoxOpen, navigate]);

  const isComposeBoxOpen = sharedState?.compose?.isComposeBoxOpen;

  return window?.js_featureTagsManager?.isFeatureTag('mediaSystem') && isComposeBoxOpen ? (
    <ComposeBoxProvider>
      <MediaSystemComponents />
    </ComposeBoxProvider>
  ) : null;
};
