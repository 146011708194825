import { ResponsiveHeatMap } from '@nivo/heatmap';
import { useMemo } from 'react';

export const formatMonthAndDay = (date: Date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('en-US', { month: 'short' });

  return `${day} ${month}`;
};

type ChartProps = {
  followersActivity: UseGetTikTokPublishingReturnType['followersOnline']['followersActivity'];
};

const formatHour = (value: string) => {
  const [hour] = value.split(':').map(Number);
  const formattedHour = hour % 12 || 12;
  const period = hour < 12 ? 'AM' : 'PM';

  return `${formattedHour} ${period}`;
};

export const Chart = ({ followersActivity }: ChartProps) => {
  const tickValues = useMemo(() => {
    return followersActivity[0]?.data.filter((_, index) => index % 2 === 0).map((item) => item.x);
  }, [followersActivity]);

  return (
    <ResponsiveHeatMap
      animate={false}
      data={followersActivity}
      enableLabels={false}
      margin={{ top: 44, right: 0, bottom: 40, left: 35 }}
      valueFormat=">-.2s"
      axisTop={{
        tickSize: 4,
        tickValues,
        format: (value: string) => formatHour(value),
        tickPadding: 5,
        tickRotation: -90,
        legend: '',
        legendOffset: 48,
        truncateTickAt: 0,
      }}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -72,
        truncateTickAt: 0,
      }}
      colors={{
        scheme: 'purple_red',
        type: 'diverging',
        divergeAt: 0.5,
      }}
      emptyColor="#555555"
      legends={[
        {
          anchor: 'bottom',
          translateX: 0,
          translateY: 24,
          length: 400,
          thickness: 8,
          direction: 'row',
          tickPosition: 'after',
          tickSize: 3,
          tickSpacing: 4,
          tickOverlap: false,
          tickFormat: '>-.2s',
          title: 'Value →',
          titleAlign: 'start',
          titleOffset: 4,
        },
      ]}
      cellComponent="circle"
      sizeVariation={{
        sizes: [0.3, 1.2],
      }}
      // forceSquare
      enableGridX
      enableGridY
    />
  );
};
