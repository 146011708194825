import { ResponsivePie } from '@nivo/pie';
import { getLuminance } from 'polished';
import { capitalizeFirst, turnCamelCaseIntoReadableText } from '@sendible/common';

type ChartProps = {
  audienceGenders: UseGetTikTokDemographicsReturnType['audienceGenders'];
};

const customColors = ['#E10543', '#0075DB', '#000000'];

export const Chart = ({ audienceGenders }: ChartProps) => {
  return (
    <ResponsivePie
      data={audienceGenders}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors={customColors}
      animate={false}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLinkLabel={(e) => capitalizeFirst(turnCamelCaseIntoReadableText(String(e.id)).toLowerCase())}
      enableArcLabels
      arcLabelsTextColor={(bar) => (getLuminance(bar.color) > 0.5 ? '#000000' : '#FFFFFF')}
      legends={[
        {
          data: audienceGenders.map(({ id, percentage, value }, index) => {
            return {
              id,
              label: capitalizeFirst(turnCamelCaseIntoReadableText(String(id)).toLowerCase()),
              percentage,
              value,
              color: customColors[index],
            };
          }),
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};
