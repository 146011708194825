import { SocialColors } from '@sendible/design-system/src/theme';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { borderRadius, colors } }) => css`
    border-radius: ${borderRadius.sm};
    box-shadow: inset 0px 0px 0px 1px ${colors.brandDarkAlpha20};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
    scroll-margin-left: 2rem;
    width: 15rem;
    user-select: none;

    p {
      margin: unset;
    }
  `}
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
`;

export const DateInfo = styled.p`
  ${({ theme: { colors, fontSizes } }) => css`
    color: ${colors.brandDarkTint30};
    font-size: ${fontSizes.s12};
    line-height: 1.33em;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  `}
`;

export const Profile = styled.div`
  display: flex;
  gap: 0.75rem;
`;

export const ProfileInfo = styled.div`
  ${({ theme: { colors, fontSizes, fontWeights } }) => css`
    display: flex;
    flex-direction: column;

    p {
      color: ${colors.brandDarkTint30};
      font-size: ${fontSizes.s12};
      line-height: 1.33em;
      letter-spacing: 0.3px;

      b {
        color: ${colors.brandDark};
        font-weight: ${fontWeights.medium};
      }
    }
  `}
`;

export const ProfileThumbBox = styled.div`
  ${({ theme: { borderRadius, colors } }) => css`
    border-radius: ${borderRadius.sm};
    box-shadow: inset 0 0 0 1px ${colors.brandDarkAlpha20};
    height: 2rem;
    position: relative;
    width: 2rem;
    overflow: visible;
    user-select: none;

    div {
      z-index: 2;
    }

    img {
      border-radius: ${borderRadius.sm};
      height: 100%;
      object-fit: cover;
      position: relative;
      width: 100%;
      z-index: 1;
    }
  `}
`;

export const IconBox = styled.div<{ $color: SocialColors }>`
  ${({ theme: { colors }, $color }) => css`
    align-items: center;
    background: ${colors[$color]};
    border-radius: 1rem;
    bottom: -0.125rem;
    box-shadow: 0 0 0 2px ${colors.brandLight};
    display: flex;
    height: 0.875rem;
    justify-content: center;
    position: absolute;
    right: -0.125rem;
    width: 0.875rem;

    .icon {
      height: 0.625rem;
      width: 0.625rem;
    }
  `}
`;

export const Caption = styled.p`
  ${({ theme: { colors, fontSizes } }) => css`
    align-self: stretch;
    color: ${colors.brandDark};
    line-height: 1.5em;
    display: -webkit-box;
    font-size: ${fontSizes.s12};
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  `}
`;

export const Thumb = styled.a`
  ${({ theme: { colors } }) => css`
    background: ${colors.brandDarkTint95};
    cursor: pointer;
    height: 15rem;
    margin-left: 1px;
    margin-right: 1px;
    position: relative;
    width: calc(15rem - 2px);

    .icon {
      height: 3rem;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 3rem;

      path {
        stroke: ${colors.brandDarkTint80};
      }
    }

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }

    &:focus-within {
      outline: 2px solid ${colors.brandPrimaryAlpha60};
      outline-offset: 2px;
    }
  `}
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
`;

export const DataItem = styled.div<{ $hideBorder?: boolean }>`
  ${({ theme: { colors, fontSizes, fontWeights }, $hideBorder }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0;

    ${!$hideBorder &&
    css`
      border-bottom: 1px solid ${colors.brandDarkTint90};
    `}

    p {
      color: ${colors.brandDarkTint30};
      font-size: ${fontSizes.s12};
      line-height: 1.33em;
      letter-spacing: 0.3px;

      b {
        color: ${colors.brandDark};
        font-weight: ${fontWeights.regular};
      }
    }
  `}
`;
