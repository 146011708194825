import { Box, Button, Select, socialMedias, SocialSelect, Tooltip, useToastNotificationContext } from '@sendible/design-system';
import { useTranslation } from 'react-i18next';
import { jsPDF as JsPDF } from 'jspdf';
import domtoimage from 'dom-to-image-more';
import { NotificationTypeEnum } from '@sendible/design-system/src/components/molecules/Toast';
import { useNavigate } from 'react-router-dom';
import { useGetTikTokProfiles } from '@sendible/frontendv2/src/models/reports';
import { pages } from '@sendible/frontendv2/src/routes';
import { Group, HeaderContainer } from './index.styles';
import { useTikTokReportContext } from '../../context';
import { useReportsContext } from '../../../context';

export const Header = () => {
  const { t } = useTranslation('reports');
  const downloadReportTooltipText = t('download_report_tooltip');
  const navigate = useNavigate();

  const { data } = useGetTikTokProfiles();
  const { date, setDate, currentAccountObject } = useTikTokReportContext();
  const { showToastNotification } = useToastNotificationContext();
  const { setIsPrint } = useReportsContext();

  const downloadAsPDF = async () => {
    const generateTimeout = 1500; // this is to allow charts to resize, which happens asynchronously

    setIsPrint(true);

    setTimeout(() => {
      const node = document.getElementsByClassName('v2-report-container')[0] as HTMLElement;

      domtoimage
        .toPng(node, { width: node.offsetWidth, height: node.offsetHeight })
        .then(function (imageDataUrl: string) {
          const imageCapture = new Image();

          imageCapture.src = imageDataUrl;

          imageCapture.onload = () => {
            const pdf = new JsPDF({
              orientation: imageCapture.width < imageCapture.height ? 'p' : 'l',
              unit: 'pt',
              format: [imageCapture.width, imageCapture.height],
            });

            pdf.addImage(imageCapture, 'JPEG', 0, 0, imageCapture.width, imageCapture.height);
            pdf.save('tiktok-report.pdf');

            setIsPrint(false);
          };
        })
        .catch(function () {
          showToastNotification({
            notificationText: 'There was an issue generating your report PDF. Please try again or contact support',
            type: NotificationTypeEnum.Error,
            showCloseButton: true,
          });

          setIsPrint(true);
        });
    }, generateTimeout);
  };

  const dateRangeItems: TikTokReportDate[] = [
    { text: 'Last 7 days', altText: 'vs Previous 7 days', action: 'last7Days' },
    { text: 'Last 30 days', altText: 'vs Previous 30 days', action: 'last30Days' },
    { text: 'Last 60 days', altText: 'vs Previous 60 days', action: 'last60Days' },
  ];

  const socialMediaProfileType = socialMedias.find((value) => value === 'tiktok');

  if (!data?.accounts.length) {
    return null;
  }

  if (!currentAccountObject) return null;

  return (
    <HeaderContainer
      padding={['s12', 's12', 's12', 's12']}
      contentJustify="space-between"
    >
      <Box horizontalGap="s12">
        <SocialSelect
          onSelect={(option) => {
            const accountObject = data.accounts.find(({ username }) => username === option);

            if (accountObject) {
              navigate(`${pages.Measure.tiktok}/${accountObject.id}`);
            }
          }}
          options={data.accounts.map((account) => {
            return { action: account.username, label: account.username };
          })}
          selectedOption={currentAccountObject?.username}
          socialMedia={socialMediaProfileType}
          thumbnailUrl={currentAccountObject?.avatar}
        />
      </Box>
      <Group>
        <Select
          extraText={date.altText}
          onSelect={(option) => {
            const item = dateRangeItems.find(({ action }) => action === option);

            if (item) setDate(item);
          }}
          options={dateRangeItems.map(({ text, action }) => {
            return { label: text, action };
          })}
          selectedOption={date.text}
        />
        <Tooltip
          content={downloadReportTooltipText}
          defaultPosition="bottom-end"
        >
          <Button
            onClick={downloadAsPDF}
            icon="download"
            label="Download PDF"
            appearance="primary"
            variation="outline"
            size={14}
          />
        </Tooltip>
      </Group>
    </HeaderContainer>
  );
};
