/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';
import { Box, Button, Heading, Icon } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { imageUtils, stringUtils } from '@sendible/common';
import { PostModel, useCampaignsReportsModel } from '@sendible/frontendv2/src/models/reports';
import {
  ProfilePicture,
  ProfileUserName,
  TopPostsOrderContainer,
  TopPostsInsightColumnContainer,
  TopPostMessageContainer,
  PostEngagementContainer,
  EmptyResults,
  Table,
} from './index.styles';
import { FullWidthBox, Header, PaginationContainer } from '../index.styles';
import { SupportedMedias } from '../SupportedMedias';
import { ReportTypes } from '../../../consts';
import { useReportsContext } from '../../../context';
import { ModuleErrorContainer } from '../../../index.styles';

type TopPostsProps = {
  title: string;
  type: ReportTypes;
  id: number | null;
  defaultErrorMessage: string;
  emptyErrorMessage: string;
  notMoreDataErrorMessage: string;
};

export const TopPosts = ({ type, id, title, defaultErrorMessage, emptyErrorMessage, notMoreDataErrorMessage }: TopPostsProps) => {
  const { getCampaignPosts } = useCampaignsReportsModel();
  const [postResponse, setPostResponse] = useState<PostModel[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<string>('descending');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { t } = useTranslation('common_terms');
  const { isPrint } = useReportsContext();

  const postsComents = t('posts_comments').toUpperCase();
  const postsLikes = t('posts_likes').toUpperCase();
  const postsInteractions = t('posts_interactions').toUpperCase();
  const engagingPosts = t('Most engaging posts').toUpperCase();

  useEffect(() => {
    setPage(1);
    setOrderBy('descending');
  }, [id]);

  useEffect(() => {
    const fetchPostsReport = async () => {
      try {
        if (id != null) {
          let results = null;

          if (type === ReportTypes.CAMPAIGN_REPORT_TYPE) {
            results = await getCampaignPosts(id, page, orderBy, '');
          }

          results = results.map((post: PostModel) => {
            if (post.socialNetwork.includes('Facebook')) {
              post.message = stringUtils.convertFacebookTagsToHumanReadable(post.message);
            }

            return post;
          });

          setPostResponse(results);

          if (results.length == 0 && page == 1) {
            setErrorMessage(emptyErrorMessage);
          } else {
            setErrorMessage(null);
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setErrorMessage(defaultErrorMessage);
      }
    };

    fetchPostsReport();
  }, [id, page, orderBy]);

  if (errorMessage) {
    return (
      <FullWidthBox padding={['s12', 's12', 's12', 's12']}>
        <ModuleErrorContainer>
          <Heading
            semantic="h6"
            variation="component-title_20"
            color="brandLight"
          >
            {title}
          </Heading>
          <div>{errorMessage}</div>
        </ModuleErrorContainer>
      </FullWidthBox>
    );
  }

  if (postResponse == null) {
    return null;
  }

  return (
    <Box
      horizontalGap="s12"
      padding={['s12', 's12', 's12', 's12']}
    >
      <PostEngagementContainer>
        <FullWidthBox>
          <Header>
            <Heading
              semantic="h6"
              variation="component-title_20"
            >
              {title}
            </Heading>
            <SupportedMedias />
          </Header>
        </FullWidthBox>
        <Table>
          <thead>
            <th>{engagingPosts}</th>
            <th aria-label="order">
              <TopPostsOrderContainer>
                <div>
                  <Button
                    icon="arrow_up"
                    variation="outline"
                    type="button"
                    disabled={postResponse.length == 0}
                    onClick={() => setOrderBy('ascending')}
                  />
                </div>
                <div>
                  <Button
                    icon="arrow_down"
                    variation="outline"
                    type="button"
                    disabled={postResponse.length == 0}
                    onClick={() => setOrderBy('descending')}
                  />
                </div>
              </TopPostsOrderContainer>
            </th>
            <th>
              <TopPostsInsightColumnContainer>
                <Icon
                  className="type-icon"
                  color="brandDark"
                  name="heart"
                />
                <span>{postsLikes}</span>
              </TopPostsInsightColumnContainer>
            </th>
            <th>
              <TopPostsInsightColumnContainer>
                <Icon
                  className="type-icon"
                  color="brandDark"
                  name="chat"
                />
                <span>{postsComents}</span>
              </TopPostsInsightColumnContainer>
            </th>
            <th>
              <TopPostsInsightColumnContainer>
                <Icon
                  className="type-icon"
                  color="brandDark"
                  name="conversation"
                />
                <span>{postsInteractions}</span>
              </TopPostsInsightColumnContainer>
            </th>
          </thead>
          <tbody>
            {page > 1 && postResponse.length == 0 ? (
              <tr>
                <td colSpan={5}>
                  <EmptyResults>{notMoreDataErrorMessage}</EmptyResults>
                </td>
              </tr>
            ) : (
              <>
                {postResponse.map((post: PostModel, index: number) => (
                  <tr key={index}>
                    <td>
                      <Box orientation="column">
                        <Box margin={['s0', 's0', 's0', 's8']}>
                          <ProfilePicture>
                            <img
                              src={post.profilePicture}
                              alt={post.profileName}
                            />
                          </ProfilePicture>
                          <ProfileUserName>
                            {post.profileName}
                            <p>
                              {post.socialNetwork} - {DateTime.fromFormat(post.dateMessage, 'yyyy/MM/dd HH:mm:ss ZZZ').toFormat('dd LLL yyyy HH:mm')}
                            </p>
                          </ProfileUserName>
                        </Box>
                        <TopPostMessageContainer>
                          {post.postPicture ? (
                            <>
                              {isPrint ? (
                                <img
                                  src={post.postPicture}
                                  alt={post.socialNetwork}
                                  onError={imageUtils.removeImageOnError}
                                  crossOrigin="anonymous"
                                />
                              ) : (
                                <img
                                  src={post.postPicture}
                                  alt={post.socialNetwork}
                                  onError={imageUtils.removeImageOnError}
                                />
                              )}
                            </>
                          ) : null}
                          <p>{post.message}</p>
                        </TopPostMessageContainer>
                      </Box>
                    </td>
                    <td>
                      <TopPostsInsightColumnContainer>{post.likes}</TopPostsInsightColumnContainer>
                    </td>
                    <td>
                      <TopPostsInsightColumnContainer>{post.comments}</TopPostsInsightColumnContainer>
                    </td>
                    <td>
                      <TopPostsInsightColumnContainer>{post.likes + post.comments}</TopPostsInsightColumnContainer>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </Table>
        <PaginationContainer contentJustify="center">
          <Button
            icon="arrow_left"
            label="PREV"
            type="button"
            disabled={page == 1}
            onClick={() => setPage(page - 1)}
          />
          <Button
            icon="arrow_right"
            label="NEXT"
            type="button"
            iconPosition="right"
            disabled={postResponse.length == 0 || postResponse.length < 5}
            onClick={() => setPage(page + 1)}
          />
        </PaginationContainer>
      </PostEngagementContainer>
    </Box>
  );
};
