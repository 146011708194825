import { QuickActions } from './types';

export const AI_ASSIST_QUICK_ACTIONS = {
  hashtags: QuickActions.Hashtags,
  rephrase: QuickActions.Rephrase,
  spelling: QuickActions.Spelling,
  shorten: QuickActions.Shorten,
  lengthen: QuickActions.Lengthen,
  customiseActionsArray: [
    { tab: 'compose-facebook', action: QuickActions.Facebook },
    { tab: 'compose-twitter', action: QuickActions.Twitter },
    { tab: 'compose-instagram', action: QuickActions.Instagram },
    { tab: 'compose-googlemybusinesslocation', action: QuickActions.GMB },
    { tab: 'compose-linkedin', action: QuickActions.LinkedIn },
    { tab: 'compose-pinterest', action: QuickActions.Pinterest },
    { tab: 'compose-tiktok', action: QuickActions.Tiktok },
    { tab: 'compose-threads', action: QuickActions.Threads },
  ],
};
