import { ResponsiveSwarmPlot } from '@nivo/swarmplot';
import { weekDaysArray } from '@sendible/frontendv2/src/pages/Reports/consts';
import { Tooltip } from './Tooltip';

export const formatMonthAndDay = (date: Date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('en-US', { month: 'short' });

  return `${day} ${month}`;
};

type ChartProps = {
  posts: UseGetTikTokPublishingReturnType['publishingBehaviour']['posts'];
};

export const Chart = ({ posts }: ChartProps) => {
  const volumeMapped = posts.map(({ volume }) => volume);
  const minScale = Math.min(...volumeMapped);
  const maxScale = Math.max(...volumeMapped);

  return (
    <ResponsiveSwarmPlot
      data={posts}
      animate={false}
      groups={weekDaysArray}
      value="hour"
      valueScale={{
        type: 'linear',
        min: 0, // 12 AM
        max: 24, // 11 PM
      }}
      colorBy="id"
      colors={posts.map((item) => item.color)}
      size={{
        key: 'volume',
        values: [minScale, maxScale],
        sizes: [8, 24],
      }}
      axisRight={null}
      axisTop={null}
      axisLeft={{
        tickValues: 8,
        format: (d: number) => {
          const hours = d % 24;
          const period = hours >= 12 ? 'PM' : 'AM';
          const formattedHours = hours % 12 || 12;

          return `${formattedHours} ${period}`;
        },
      }}
      axisBottom={{
        tickValues: weekDaysArray,
      }}
      margin={{ top: 24, right: 0, bottom: 20, left: 40 }}
      tooltip={Tooltip}
    />
  );
};
