export const makeNumbersReadFriendly = (number: number, fraction?: number): string =>
  number.toLocaleString('en', {
    notation: 'compact',
    maximumFractionDigits: fraction || 1,
  });

export const findMedian = (arr: number[]) => {
  arr.sort((a, b) => a - b);

  const middleIndex = Math.floor(arr.length / 2);

  if (arr.length % 2 === 0) {
    return (arr[middleIndex - 1] + arr[middleIndex]) / 2;
  }

  return arr[middleIndex];
};
