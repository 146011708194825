export const chartDummyData: UseGetTikTokPublishingReturnType = {
  publishingBehaviour: {
    posts: [
      {
        color: '#0075DB',
        engagementLevel: 'Low',
        group: 'Sun',
        hour: 16.416666666666668,
        id: '0',
        timestamp: '2024-11-03T16:25:14',
        volume: 0,
      },
      {
        color: '#E10543',
        engagementLevel: 'High',
        group: 'Sun',
        hour: 16.416666666666668,
        id: '1',
        timestamp: '2024-11-03T16:25:14',
        volume: 4,
      },
      {
        color: '#0075DB',
        engagementLevel: 'Low',
        group: 'Sun',
        hour: 16.416666666666668,
        id: '2',
        timestamp: '2024-11-03T16:25:14',
        volume: 0,
      },
      {
        color: '#0075DB',
        engagementLevel: 'Low',
        group: 'Sun',
        hour: 16.416666666666668,
        id: '3',
        timestamp: '2024-11-03T16:25:14',
        volume: 1,
      },
      {
        color: '#000',
        engagementLevel: 'Medium',
        group: 'Sun',
        hour: 16.416666666666668,
        id: '4',
        timestamp: '2024-11-03T16:25:14',
        volume: 2,
      },
      {
        color: '#000',
        engagementLevel: 'Medium',
        group: 'Sun',
        hour: 16.416666666666668,
        id: '5',
        timestamp: '2024-11-03T16:25:14',
        volume: 2,
      },
      {
        color: '#000',
        engagementLevel: 'Medium',
        group: 'Sun',
        hour: 16.416666666666668,
        id: '6',
        timestamp: '2024-11-03T16:25:14',
        volume: 3,
      },
      {
        color: '#E10543',
        engagementLevel: 'High',
        group: 'Sun',
        hour: 16.416666666666668,
        id: '7',
        timestamp: '2024-11-03T16:25:14',
        volume: 5,
      },
      {
        color: '#E10543',
        engagementLevel: 'High',
        group: 'Sun',
        hour: 16.416666666666668,
        id: '8',
        timestamp: '2024-11-03T16:25:14',
        volume: 7,
      },
    ],
    summary: {
      totalPosts: 9,
      mostFrequentDay: 'Sunday',
      mostFrequentTime: '16:00:00',
    },
  },
  followersOnline: {
    followersActivity: [
      {
        id: 'Fri',
        data: [
          {
            x: '09:00',
            y: 74,
          },
          {
            x: '12:00',
            y: 89,
          },
          {
            x: '21:00',
            y: 146,
          },
          {
            x: '23:00',
            y: 130,
          },
          {
            x: '06:00',
            y: 54,
          },
          {
            x: '01:00',
            y: 47,
          },
          {
            x: '11:00',
            y: 74,
          },
          {
            x: '14:00',
            y: 99,
          },
          {
            x: '17:00',
            y: 133,
          },
          {
            x: '19:00',
            y: 141,
          },
          {
            x: '22:00',
            y: 127,
          },
          {
            x: '00:00',
            y: 68,
          },
          {
            x: '03:00',
            y: 33,
          },
          {
            x: '04:00',
            y: 27,
          },
          {
            x: '05:00',
            y: 26,
          },
          {
            x: '13:00',
            y: 81,
          },
          {
            x: '15:00',
            y: 128,
          },
          {
            x: '16:00',
            y: 134,
          },
          {
            x: '18:00',
            y: 132,
          },
          {
            x: '02:00',
            y: 31,
          },
          {
            x: '20:00',
            y: 144,
          },
          {
            x: '08:00',
            y: 94,
          },
          {
            x: '10:00',
            y: 82,
          },
          {
            x: '07:00',
            y: 89,
          },
        ],
      },
      {
        id: 'Sat',
        data: [
          {
            x: '00:00',
            y: 96,
          },
          {
            x: '04:00',
            y: 38,
          },
          {
            x: '09:00',
            y: 95,
          },
          {
            x: '19:00',
            y: 112,
          },
          {
            x: '22:00',
            y: 143,
          },
          {
            x: '03:00',
            y: 26,
          },
          {
            x: '08:00',
            y: 76,
          },
          {
            x: '11:00',
            y: 118,
          },
          {
            x: '15:00',
            y: 115,
          },
          {
            x: '18:00',
            y: 145,
          },
          {
            x: '20:00',
            y: 141,
          },
          {
            x: '06:00',
            y: 47,
          },
          {
            x: '10:00',
            y: 109,
          },
          {
            x: '14:00',
            y: 114,
          },
          {
            x: '16:00',
            y: 123,
          },
          {
            x: '21:00',
            y: 148,
          },
          {
            x: '23:00',
            y: 119,
          },
          {
            x: '01:00',
            y: 58,
          },
          {
            x: '02:00',
            y: 46,
          },
          {
            x: '05:00',
            y: 36,
          },
          {
            x: '07:00',
            y: 59,
          },
          {
            x: '12:00',
            y: 119,
          },
          {
            x: '13:00',
            y: 123,
          },
          {
            x: '17:00',
            y: 144,
          },
        ],
      },
      {
        id: 'Sun',
        data: [
          {
            x: '04:00',
            y: 36,
          },
          {
            x: '16:00',
            y: 146,
          },
          {
            x: '17:00',
            y: 122,
          },
          {
            x: '21:00',
            y: 128,
          },
          {
            x: '01:00',
            y: 67,
          },
          {
            x: '05:00',
            y: 32,
          },
          {
            x: '13:00',
            y: 127,
          },
          {
            x: '15:00',
            y: 132,
          },
          {
            x: '18:00',
            y: 149,
          },
          {
            x: '20:00',
            y: 147,
          },
          {
            x: '23:00',
            y: 104,
          },
          {
            x: '19:00',
            y: 148,
          },
          {
            x: '00:00',
            y: 86,
          },
          {
            x: '02:00',
            y: 54,
          },
          {
            x: '03:00',
            y: 42,
          },
          {
            x: '06:00',
            y: 40,
          },
          {
            x: '08:00',
            y: 80,
          },
          {
            x: '09:00',
            y: 99,
          },
          {
            x: '14:00',
            y: 136,
          },
          {
            x: '07:00',
            y: 56,
          },
          {
            x: '10:00',
            y: 118,
          },
          {
            x: '11:00',
            y: 124,
          },
          {
            x: '12:00',
            y: 117,
          },
          {
            x: '22:00',
            y: 122,
          },
        ],
      },
    ],
    summary: {
      mostFollowersCount: 149,
      mostEngagementDate: '2024-12-15T18:00:00',
    },
  },
} as UseGetTikTokPublishingReturnType;
