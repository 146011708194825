import { ComputedDatum } from '@nivo/swarmplot';

export const Tooltip = (node: ComputedDatum<UseGetTikTokPublishingReturnType['publishingBehaviour']['posts'][0]>) => {
  return (
    <div
      style={{
        padding: '1rem',
        fontSize: '.75rem',
        background: 'white',
        border: '1px solid #ccc',
        borderRadius: '.25rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <span>Date: {new Date(node.data.timestamp).toLocaleString()}</span>
      <span>Engagement: {node.data.engagementLevel}</span>
    </div>
  );
};
