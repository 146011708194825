import { ColorIndicator, Container, Item, Label } from './index.styles';

export const Legend = () => {
  const engagementColors = {
    Low: '#0075DB',
    High: '#E10543',
    Medium: '#000',
  };

  return (
    <Container className="legend">
      <Item>
        <ColorIndicator
          style={{
            background: engagementColors.Low,
          }}
        />
        <Label>Low</Label>
      </Item>
      <Item>
        <ColorIndicator
          style={{
            background: engagementColors.Medium,
          }}
        />
        <Label>Medium</Label>
      </Item>
      <Item>
        <ColorIndicator
          style={{
            background: engagementColors.High,
          }}
        />
        <Label>High</Label>
      </Item>
    </Container>
  );
};
