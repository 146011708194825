export default {
  aria_add_new: 'Add new media library',
  aria_close_delete_modal: 'Close delete media modal',
  aria_close_lightbox: 'Close lightbox',
  aria_delete_media: 'Delete media',
  aria_download_media: 'Download media',
  aria_media_item: 'Media Item, press enter to open or space to select',
  aria_media_library: 'Media Library',
  aria_media_library_list: 'Media Library List',
  aria_media_library_options: 'Media Library options',
  aria_move_slider_left: 'Move slider left',
  aria_move_slider_right: 'Move slider right',
  aria_post_to_compose: 'Post to compose',
  aria_search_current: 'Search current media library',
  aria_upload_media: 'Upload media to library',
  bulkbar_selected: 'Selected',
  button_save: 'Save',
  contact_support: 'contact support',
  content_section_not_load: 'The content section could not load.',
  content_section_try_again: 'Try loading content section again',
  delete_media_library_modal_title: 'Delete library?',
  delete_media_modal_title: 'Delete media?',
  deletion_media_modal_text: 'Are you sure that you want to delete this file? This action is not reversible.',
  deletion_modal_button_back: 'Back',
  deletion_modal_text: 'Are you sure that you want to delete {{mediaName}}? This action is not reversible.',
  deletion_multiple_medias_modal_text: 'This action is not reversible.',
  dropdown_delete: 'Delete',
  dropdown_rename: 'Rename',
  error_if_persists: 'If the problem persists,',
  error_if_persists_wl: 'If the problem persists, get in touch with your admin.',
  error_type: 'Error type',
  failed_changed: '{{oldName}} could not be changed. Try again.',
  failed_create: 'Unable to create {{name}} right now. Try again later.',
  failed_delete: 'Unable to delete {{name}} right now. Try again later.',
  header_search: 'Search',
  header_upload_button: 'Upload',
  item_changed: '{{oldName}} has been changed to {{newName}}.',
  item_created: '{{name}} has been created.',
  item_deleted: '{{name}} has been deleted.',
  media_library_not_load: 'The media library could not load.',
  media_library_try_again: 'Try loading the media library again',
  media_preview_download: 'Download',
  media_preview_input_label: 'Name',
  media_uploading_state_text: 'Uploading',
  new_media_library_button_label: 'Create library',
  new_media_library_input_label: 'Library name',
  new_media_library_modal_title: 'New media library',
  preview_medias_try_again: 'Try loading preview section again',
  preview_section_not_load: 'The preview section could not load.',
  sibebar_new_library_button: 'New',
  sidebar_title: 'Media libraries',
  toast_media_rename_error: 'Could not rename. Check your connection and try again.',
  toast_media_rename_success: 'The file has been renamed.',
  toast_medias_deletion_error: '{{name}} was not deleted. Check your connection and try again.',
  toast_multiple_medias_deletion_error: 'Some of the selected files were not deleted. Check your connection and try again.',
  toast_multiple_medias_deletion_success: '{{numberOfMedias}} files have been deleted.',
  toast_single_media_deletion_success: '{{mediaName}} has been deleted.',
  try_again_button: 'Refresh',
};
