import { useEffect, useState } from 'react';
import { Box, Button, Heading } from '@sendible/design-system/src';
import { AxiosError } from 'axios';
import { stringUtils } from '@sendible/common';
import { PostModel, useCampaignsReportsModel } from '@sendible/frontendv2/src/models/reports';
import { PostEngagementContainer, EmptyResults } from './index.styles';
import { Post } from './components/Post';
import { FullWidthBox, PaginationContainer } from '../index.styles';
import { ReportTypes } from '../../../consts';
import { ModuleErrorContainer } from '../../../index.styles';

type PostsEngagementProps = {
  title: string;
  type: ReportTypes.CAMPAIGN_REPORT_TYPE;
  id: number | null;
  socialNetwork: string;
  defaultErrorMessage: string;
  notMoreDataErrorMessage: string;
};

export const PostsEngagement = ({ type, id, title, socialNetwork, defaultErrorMessage, notMoreDataErrorMessage }: PostsEngagementProps) => {
  const { getCampaignPosts } = useCampaignsReportsModel();
  const [postResponse, setPostResponse] = useState<PostModel[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchPostsReport = async () => {
      try {
        if (id != null) {
          let results = null;

          if (type === ReportTypes.CAMPAIGN_REPORT_TYPE) {
            results = await getCampaignPosts(id, page, '', socialNetwork);
          }

          results = results.map((post: PostModel) => {
            if (post.socialNetwork.includes('Facebook')) {
              post.message = stringUtils.convertFacebookTagsToHumanReadable(post.message);
            }

            return post;
          });

          setPostResponse(results);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error instanceof AxiosError) {
          const code = error?.response?.data?.error?.code;

          if (code === 'InvalidArgument' || code === 'NotFound') {
            setErrorMessage(defaultErrorMessage);
          }
        }
      }
    };

    fetchPostsReport();
  }, [id, page]);

  if (errorMessage) {
    return (
      <ModuleErrorContainer margin={['s12', 's12', 's24', 's12']}>
        <Heading
          semantic="h6"
          variation="component-title_20"
          color="brandLight"
        >
          {title}
        </Heading>
        <div>{errorMessage}</div>
      </ModuleErrorContainer>
    );
  }

  if (postResponse == null || (postResponse.length == 0 && page == 1)) {
    return null;
  }

  return (
    <Box
      horizontalGap="s12"
      padding={['s12', 's12', 's12', 's12']}
    >
      <PostEngagementContainer>
        <FullWidthBox>
          <Heading
            semantic="h6"
            variation="component-title_20"
          >
            {title}
          </Heading>
        </FullWidthBox>
        {page > 1 && postResponse.length == 0 ? (
          <EmptyResults>{notMoreDataErrorMessage}</EmptyResults>
        ) : (
          <Box
            verticalGap="s24"
            horizontalGap="s24"
          >
            {postResponse.map((post: PostModel, index: number) => (
              <Post
                postItem={post}
                key={index}
              />
            ))}
          </Box>
        )}
        <PaginationContainer contentJustify="center">
          <Button
            icon="arrow_left"
            label="PREV"
            type="button"
            disabled={page == 1}
            onClick={() => setPage(page - 1)}
          />
          <Button
            icon="arrow_right"
            label="NEXT"
            type="button"
            iconPosition="right"
            disabled={postResponse.length == 0 || postResponse.length < 5}
            onClick={() => setPage(page + 1)}
          />
        </PaginationContainer>
      </PostEngagementContainer>
    </Box>
  );
};
