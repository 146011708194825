import { fetchWithHeaders, urls } from '@sendible/common';
import { useQueryClient } from '@tanstack/react-query';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import axios from 'axios';
import { useBackgroundUploaderContext } from '../../components/BackgroundUploader/context';
import endpoints from '../../data-layer/endpoints';
import { useMediaLibraryContext } from '../../pages/MediaLibrary/context';
import { useGetMediasQueryKey } from '../medias';
import { getValidAndInvalidFiles } from '../medias/utils/fileUploadValidation';
import { singleFileUploadSuccess, singleFileUploadFailure, singleFileUploadCancelled } from '../../pages/MediaLibrary/pendoEvents';
import { composeMediaInteractions } from '../../composeMediaInteractions';
import { MediaFileType } from '../medias/types';

type UseUploadFilesParam = {
  uploadingFrom: SourceType;
};

const completeUpload = async (
  uploadIntent: MediaUploadCompleteType,
  parts: MediaUploadedPartType[],
  params: MediaUploadParamsType
): Promise<number | undefined> => {
  const { mediaId, objectKey, upload } = uploadIntent;
  const { access_token } = params;

  const completeUploadParams = {
    access_token,
    mediaId,
  };

  const completeUploadBody = {
    uploadId: upload.id,
    objectKey,
    uploadedParts: parts,
  };

  const { result }: FetchWithHeadersResult<MediaType> = await fetchWithHeaders({
    method: endpoints.CompleteUploadMedias.method,
    url: `${urls.baseUrl}${endpoints.CompleteUploadMedias.endpoint}`,
    params: completeUploadParams,
    body: completeUploadBody,
    headers: { 'Content-Type': 'application/json' },
  });

  if (result?.status === 'New') return mediaId;

  return undefined;
};

const abortUpload = async (uploadIntent: MediaUploadIntentType, params: MediaUploadParamsType) => {
  const {
    mediaId,
    objectKey,
    upload: { id: uploadId },
  } = uploadIntent;
  const { access_token } = params;

  const abortUploadParams = {
    access_token,
    mediaId,
    objectKey,
    uploadId,
  };

  const { result }: FetchWithHeadersResult<MediaType> = await fetchWithHeaders({
    method: endpoints.AbortUploadMedias.method,
    url: `${urls.baseUrl}${endpoints.AbortUploadMedias.endpoint}`,
    params: abortUploadParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result?.status === 'UploadFailed';
};

export const useUploadFiles = ({ uploadingFrom }: UseUploadFilesParam) => {
  const {
    addFile,
    updateFileProgress,
    updateFileError,
    updateFileReady,
    addAbortAPIToFile,
    setUploadingIdsFromComposeBox,
    setUploadingIdsFromMediaLibrary,
  } = useBackgroundUploaderContext();
  const { activeMediaLibrary } = useMediaLibraryContext();
  const queryClient = useQueryClient();
  const mediasQueryKey = useGetMediasQueryKey();
  let abortedIds: string[] = [];

  const isAborted = (fileId: string) => {
    return abortedIds.includes(fileId);
  };

  const [
    {
      user: { accessToken },
    },
  ] = useBridgeContext();

  const params: MediaUploadParamsType = {
    access_token: accessToken,
  };

  if (uploadingFrom === 'media-library') params.mediaLibraryId = activeMediaLibrary.id;

  const uploadParts = (
    uploadIntent: MediaUploadIntentType,
    file: File,
    fileId: string,
    abortController: AbortController
  ): Promise<MediaUploadedPartType[]> => {
    const {
      upload: { parts },
      mediaId,
    } = uploadIntent;
    const progressTracker: number[] = [];

    if (uploadingFrom === 'compose-box') composeMediaInteractions.attachMediaToActiveTab(mediaId, file);

    return Promise.all(
      parts.map(async (part: MediaUploadPartType): Promise<MediaUploadedPartType> => {
        const { url, partNumber, partSize } = part;
        const partStartByte = partSize * (partNumber - 1);
        const partEndByte = partSize + partStartByte;
        const partialBlob = file.slice(partStartByte, partEndByte);

        const uploadedPartResponse = await axios.put(url, partialBlob, {
          headers: { 'content-type': file.type },
          signal: abortController.signal,
          onUploadProgress: (progressEvent) => {
            if (progressEvent?.total) {
              progressTracker[partNumber - 1] = Math.round((progressEvent.loaded * 100) / progressEvent.total);

              const totalProgress = progressTracker.reduce((sum, progress) => sum + progress, 0) / parts.length;

              if (!isAborted(fileId)) updateFileProgress({ fileId, uploadProgress: totalProgress, abortController, mediaId });
            }
          },
        });

        if (uploadedPartResponse && uploadedPartResponse.status === 200 && uploadedPartResponse?.headers) {
          const eTag = uploadedPartResponse.headers.etag;

          if (eTag) {
            return {
              eTag: eTag.replaceAll('"', ''),
              partNumber,
            };
          }
        }

        throw new Error('Failed to upload part');
      })
    );
  };

  const uploadFile = async (file: File, fileId: string, abortController: AbortController) => {
    const { method, endpoint } = endpoints.UploadMedias;

    const filename = file.name.replace(/\.[^/.]+$/, '');
    const { access_token } = params;

    const body: MediaUploadBodyType = {
      source: {
        type: 'File',
        filename,
        size: file.size,
        contentType: file.type,
      },
    };

    if (uploadingFrom === 'media-library') {
      body.mediaLibraryId = activeMediaLibrary.id;
      setUploadingIdsFromMediaLibrary((prev) => [...prev, fileId]);
    } else {
      setUploadingIdsFromComposeBox((prev) => [...prev, fileId]);
    }

    const { result } = await fetchWithHeaders({
      method,
      url: `${urls.baseUrl}${endpoint}`,
      params: { access_token },
      body,
    });

    addAbortAPIToFile(fileId, () => {
      abortUpload(result, params);
      abortedIds = [...abortedIds, fileId];
    });

    const cleanStateIds = () => {
      if (uploadingFrom === 'compose-box') {
        setUploadingIdsFromComposeBox((prev) => {
          const index = prev.indexOf(fileId);
          const { [index]: removedOne, ...rest } = prev;

          return Object.values(rest) as string[];
        });
      } else {
        setUploadingIdsFromMediaLibrary((prev) => {
          const index = prev.indexOf(fileId);
          const { [index]: removedOne, ...rest } = prev;

          return Object.values(rest) as string[];
        });
      }
    };

    try {
      const uploadedParts = await uploadParts(result, file, fileId, abortController);

      if (!isAborted(fileId)) {
        cleanStateIds();

        return completeUpload(result, uploadedParts, params);
      }
    } catch (error) {
      if (!isAborted(fileId)) {
        cleanStateIds();
        abortUpload(result, params);

        throw error;
      }
    }
  };

  const getValidSingleTypeFiles = (files: File[]): File[] => {
    if (!files.length) return [];

    const firstFileType = files[0].type.split('/')[0];

    if (firstFileType.toLowerCase() === MediaFileType.Video.toLowerCase()) {
      return [files[0]];
    }

    return files.filter((file) => file.type.startsWith(firstFileType));
  };

  const uploadFiles = async (files: File[]) => {
    const { validFiles, invalidFiles } = getValidAndInvalidFiles(files);

    const source = uploadingFrom;
    const mediaLibraryName = uploadingFrom === 'media-library' ? activeMediaLibrary.name : undefined;

    invalidFiles.forEach((file) => {
      const fileId = addFile(file, new AbortController(), source, mediaLibraryName);

      updateFileError({
        fileId,
        errorMessage: 'uploader_items_invalid',
      });
    });

    if (validFiles.length) {
      files.forEach(async (file) => {
        const filePropertiesForPendo = {
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
        };

        const abortController = new AbortController();

        const fileId = addFile(file, abortController, source, mediaLibraryName);

        try {
          const mediaId = await uploadFile(file, fileId, abortController);

          if (mediaId) {
            updateFileReady(fileId, mediaId);
            window.pendo.track(singleFileUploadSuccess, filePropertiesForPendo);

            if (uploadingFrom === 'media-library') queryClient.invalidateQueries({ queryKey: mediasQueryKey });
          } else {
            window.pendo.track(singleFileUploadCancelled, filePropertiesForPendo);
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          let errorMessage;

          if (error.response?.data?.error) {
            const { message, type } = error.response.data.error as DLAPIErrorType;

            errorMessage = `${type}: ${message}`;
          } else if (error.message) {
            errorMessage = error.message;
          } else {
            errorMessage = error;
          }

          updateFileError({ fileId, errorMessage });
          window.pendo.track(singleFileUploadFailure, filePropertiesForPendo);
        }
      });
    }
  };

  return {
    uploadFiles,
    getValidSingleTypeFiles,
  };
};
