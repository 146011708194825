import { Box, Button, Tooltip } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toPng } from 'html-to-image';
import { jsPDF as JsPDF } from 'jspdf';
import { socialMedias, SocialSelect } from '@sendible/design-system/src/components/molecules/SocialSelect';
import { pages } from '@sendible/frontendv2/src/routes';
import { HeaderContainer, PrintIconContainer } from './index.styles';
import { ReportsList, ReportWidth } from '../../../consts';
import { useReportsContext } from '../../../context';

export type ReportList = {
  name: string;
  id: number;
};

type HeaderProps = {
  reportsList: ReportsList[];
  currentReportId: number | 0;
  profileType?: string;
  profileSelect?: null | ((reportObject: ReportsList) => void);
  dateSelect?: null | ((date: string) => void);
};

export const Header = ({ reportsList, currentReportId, profileType, profileSelect, dateSelect }: HeaderProps) => {
  const { t } = useTranslation('reports');
  const generateReportButtonText = t('generate_report_button');
  const downloadReportTooltipText = t('download_report_tooltip');
  const navigate = useNavigate();
  const { setIsPrint } = useReportsContext();
  const [selectedReport, setSelectedReport] = useState(currentReportId);
  const [selectedDate, setSelectedDate] = useState(0);

  const handleGenerateReport = () => {
    window.pendo.track('[Report hub] Generate campaign report');
    navigate(`${pages.Measure.campaigns}/${selectedReport}`);
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedReport(parseInt(event.target.value, 10));
  };

  const downloadAsPDF = async () => {
    setIsPrint(true);

    try {
      setTimeout(async () => {
        const node = document.getElementsByClassName('v2-report-container')[0];

        if (node) {
          const dataUrl = await toPng(node as HTMLElement, { quality: 0.95, canvasWidth: ReportWidth, skipAutoScale: true, cacheBust: true });
          const imageCapture = new Image();

          imageCapture.src = dataUrl;

          imageCapture.onload = () => {
            const pdf = new JsPDF({
              orientation: imageCapture.width < imageCapture.height ? 'p' : 'l',
              unit: 'pt',
              format: [imageCapture.width, imageCapture.height],
            });

            pdf.addImage(imageCapture, 'JPEG', 0, 0, imageCapture.width, imageCapture.height);
            pdf.save('campaign-report.pdf');

            setIsPrint(false);
          };
        }
      }, 1500);
    } catch {
      setIsPrint(false);
    }
  };

  const selectedReportObject = reportsList.find(({ id }) => id === selectedReport);

  const dateRangeItems = [
    { text: 'Last 7 days', altText: 'vs Previous 7 days' },
    { text: 'Last 30 days', altText: 'vs Previous 30 days' },
    { text: 'Last 60 days', altText: 'vs Previous 60 days' },
  ];

  const socialMediaProfileType = socialMedias.find((value) => value === profileType);

  return (
    <HeaderContainer
      padding={['s12', 's12', 's12', 's12']}
      contentJustify="space-between"
    >
      <Box horizontalGap="s12">
        {profileSelect && selectedReportObject && profileType ? (
          <SocialSelect
            extraText=""
            onSelect={(option) => {
              const reportObject = reportsList.find(({ name }) => name === option);

              if (reportObject) {
                setSelectedReport(reportObject.id);
                profileSelect(reportObject);
              }
            }}
            options={reportsList.map((report) => {
              return { action: report.name, label: report.name };
            })}
            selectedOption={selectedReportObject.name}
            socialMedia={socialMediaProfileType}
            thumbnailUrl={selectedReportObject.thumbnailUrl}
          />
        ) : (
          <>
            <Button
              label={generateReportButtonText}
              variation="outline"
              appearance="primary"
              onClick={handleGenerateReport}
            />
            <select
              value={selectedReport}
              onChange={handleChange}
            >
              {reportsList.map(({ name, id }) => {
                return (
                  <option
                    value={id}
                    key={`report_header_${id}`}
                  >
                    {name}
                  </option>
                );
              })}
            </select>
          </>
        )}
      </Box>
      <PrintIconContainer
        margin={['s0', 's12', 's0', 's0']}
        horizontalGap="s4"
      >
        {dateSelect && (
          <SocialSelect
            extraText={dateRangeItems[selectedDate].altText}
            onSelect={(option) => {
              const index = dateRangeItems.findIndex(({ text }) => text === option);

              setSelectedDate(index || 0);
              dateSelect(option);
            }}
            options={dateRangeItems.map(({ text }) => {
              return { action: text, label: text };
            })}
            selectedOption={dateRangeItems[selectedDate].text}
          />
        )}
        <Tooltip
          content={downloadReportTooltipText}
          defaultPosition="bottom-end"
        >
          <Button
            onClick={downloadAsPDF}
            icon="download"
            label="Download PDF"
            appearance="primary"
            variation="outline"
            size={14}
          />
        </Tooltip>
      </PrintIconContainer>
    </HeaderContainer>
  );
};
